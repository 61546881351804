var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { P as PromoUtils } from "./promotion.planning.js";
import { n as normalizeComponent } from "./index.js";
import "@kraftheinz/common";
import "numeral";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("filter-drawer-composer", { attrs: { "default-query-strings": _vm.defaultQueryStrings, "has-filter-inactive": false, "is-advanced-filtered": _vm.isAdvancedFiltered } }, [_c("select-filter", { key: "State", attrs: { "allow-clear": true, "data-source": _vm.stateList, "label": "State", "source": "code", "source-description": "description", "source-label": function(opt) {
    return _vm.generateLabel(opt, ["region", "description"]);
  } } }), _c("select-filter", { key: "Category", attrs: { "allow-clear": true, "label": "Category", "queries": [["All", false]], "reference": "promotion-planning.common.categories", "source": "code", "source-description": "description", "source-label": function(opt) {
    return _vm.generateLabel(opt, ["code", "description"]);
  } } }), _c("select-filter", { key: "PPGCode", attrs: { "allow-clear": true, "label": "PPG", "queries": [
    ["All", false],
    ["paging", true],
    ["Include", true],
    ["distinct", true]
  ], "reference": "promotion-planning.common.products", "search-by": "Code,Description", "search-by-depends-on-value": true, "source": "code", "source-description": "description", "source-label": function(opt) {
    return _vm.generateLabel(opt, ["code", "description"]);
  } } }), _c("select-filter", { key: "Status", attrs: { "allow-clear": true, "label": "Status", "mode": "multiple", "reference": "promotion-planning.promo-status", "source": "name", "source-description": "name", "source-label": "name" } })], 1);
};
var staticRenderFns$1 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "FilterPromoEntry",
  mixins: [PromoUtils],
  inject: ["can", "crud", "resourceProps"],
  data() {
    const [yearProps] = this.resourceProps;
    return {
      defaultAdvancedFilters: {
        Category: void 0,
        PPGCode: void 0,
        State: void 0,
        Status: void 0
      },
      stateApiUrl: `${apiUrl$1}/promotion-planning/common/states`,
      stateList: [],
      yearProps
    };
  },
  computed: {
    advancedFilterKeys() {
      return Object.keys(this.defaultAdvancedFilters);
    },
    advancedFilters() {
      return Array.from(this.crud.getFilters()).filter(([key]) => this.advancedFilterKeys.includes(key)).map(([key, filter]) => ({
        key,
        value: filter.value
      })).reduce((filters, filter) => __spreadProps(__spreadValues({}, filters), { [filter.key]: filter.value }), {});
    },
    commonYears() {
      return this.yearProps.crud.getList();
    },
    defaultYear() {
      const finYear = this.commonYears.find((year) => year.isDefault);
      return finYear ? finYear.year : null;
    },
    defaultQueryStrings() {
      return [["year", this.defaultYear]];
    },
    isAdvancedFiltered() {
      return Object.entries(this.advancedFilters).some(([key, value]) => value !== this.defaultAdvancedFilters[key]);
    }
  },
  mounted() {
    this.getDataState();
  },
  methods: {
    async getDataState() {
      const filterRegion = this.crud.getFilter("Region");
      this.stateList = await this.fetchSelectedList(filterRegion ? filterRegion.value : "", this.stateApiUrl);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var FilterLandingPage = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "promotion-planning.common.clusters", "api-url": _vm.apiUrl, "resource-id-name": "code" } }), _c("resource", { attrs: { "name": "promotion-planning.common.categories", "api-url": _vm.apiUrl, "resource-id-name": "code" } }), _c("resource", { attrs: { "name": "promotion-planning.common.sub-categories", "api-url": _vm.apiUrl, "resource-id-name": "code" } }), _c("resource", { attrs: { "name": "promotion-planning.common.regions", "api-url": _vm.apiUrl, "resource-id-name": "code" } }), _c("resource", { attrs: { "name": "promotion-planning.common.states", "api-url": _vm.apiUrl, "resource-id-name": "code" } }), _c("resource", { attrs: { "name": "promotion-planning.common.customers", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "promotion-planning.common.products", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "promotion-planning.promo-status", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": ["promotion-planning.common.year"], "resource-id-name": ["year"] } }, [_c("resource", { attrs: { "name": "promotion-planning.promo", "api-url": _vm.apiUrl, "redirect-route": "/promotion-planning/landing-page" } }, [_c("filter-landing-page")], 1)], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = "";
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    FilterLandingPage
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
